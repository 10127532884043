import axios from 'axios'
import { message } from 'antd'
let baseUrl = 'http://jsyang.top'
let requestHeader = 'Content-Type:application/json'
const service = axios.create({
  baseURL:baseUrl,
  timeout:10000
})
service.interceptors.request.use(
  config=>{
    console.log(config)
    let token = config.token
    config.headers = requestHeader
    return config
  },
  error=>{
    Promise.reject(error)
  }
)
service.interceptors.response.use(
  response=>{
    if(response.config.responseType === 'blob'){
      if(response.code === 200){
        return response.data
      }else{
        return Promise.reject()
      }
    }else{
      const res = response.data
      if(res.code === 200){
        return res.data
      }else if(res.code === 407){
        // 登录失效跳转登录页面
      }else{
        message.error({
          content:res.errorMessage
        })
        return Promise.reject()
      }
    }
  },
  error=>{
    message.error({
      content:'err'
    })
    return Promise.reject(error)
  }
)
export default service