import React, { Component } from "react";
import banner from "../../asstes/images/banner.jpg";
import "./home.scss";
import StudyList from "./studyList";
import UpMaster from "./upMaster";
import WebsitInfo from './websiteInfo'
class home extends Component {
  constructor(props) {
    super(props);
this.state = {
      today: "凡是过往皆是序章，所有将来皆为可盼",
      day: "",
      oninput: true,
    };
  }
  componentDidMount() {
    let count = 0;
    setInterval(() => {
      count++;
      this.setState({
        day: this.state.today.slice(0, count),
      });
      if (count >= this.state.today.length) {
        count = 0;
      }
    }, 300);
    setInterval(() => {
      this.setState({
        oninput: !this.state.oninput,
      });
    }, 500);
  }
  render() {
    const { day, oninput } = this.state;
    return (
      <div className="index">
        <div className="banner">
          <h1>
            <span>life</span> <span>smile</span>
            <p>
              {day}
              <span
                className="oninput"
                style={{ visibility: oninput ? "visible" : "hidden" }}
              ></span>
            </p>
          </h1>
          <img src={banner} alt="" />
        </div>
        <div className="content">
          <div className="w">
            <StudyList></StudyList>
            <WebsitInfo />
            <UpMaster data = { this.state.today }></UpMaster>
          </div>
        </div>
      </div>
    );
  }
}

export default home;
