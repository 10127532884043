import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./say.scss";
import Like from '../../components/Like'
import { EditOutlined, ShareAltOutlined } from '@ant-design/icons'
import { sayData } from '../../mock/say'
import SayItem from './SayItem'
const say = (props) => {
  return (
    <div className="say">
      <ul>
        {sayData
          .map((item, index) => (
            <SayItem data={item} key={index} />
          ))}
      </ul>
    </div>
  );
};

export default say;
