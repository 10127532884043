import React, { useState, UseEffect } from "react";
import { AiOutlineLock } from "react-icons/ai";
import '../asstes/scss/Unauthorized.scss'

const Unauthorized = (props) => {
  return (
    <div className="unauthorized">
      <div className="block">
        <div><AiOutlineLock /></div>
        此页面你无权限查看
      </div>
    </div>
  );
};
export default Unauthorized;
