import React, { Component } from "react";
import '../asstes/scss/footer.scss'
class footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="footer">
        <a href="/" className="logo">
          YANG <img src={require('../asstes/images/cheng.png').default} alt="" />
        </a>
        <br />
        <a href="/" className="banquan">
          jsyang.top All Rights Reserved
        </a>
        <br />
        <a
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          className="num"
          target="_blank"
          rel="noreferrer"
        >
          陕ICP备2021001970号-1
        </a>
      </div>
    );
  }
}

export default footer;
