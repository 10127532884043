import react, { useState, useEffect, useRef } from "react";
import Editor from "for-editor";
import { marked } from "marked";
import Fetch from "../network/fetch";
import { userInfo } from "../network/getAdmin";
import { Button } from "antd";
import { AiOutlineFileAdd } from 'react-icons/ai'
import "../asstes/scss/writeArticle.scss";

const WriteArticle = (props) => {
  const [value, setValue] = useState("");
  const [user, setUser] = useState(null);
  const [articleTitle, setArticleTitle] = useState("");
  const [cover, setCover] = useState("");
  const [toolbar, setToolbar] = useState({
    h1: true,
    h2: true,
    h3: true,
    h4: true,
    img: true,
    link: true,
    code: true,
    preview: false,
    // expand: true,
    undo: true,
    redo: true,
    // save: true,
    subfield: true,
  });
  useEffect(async () => {
    const result = await userInfo();
    setUser(result);
  }, []);
  const handleChange = (newText) => {
    setValue(newText);
  };
  const saveMarkDown = async () => {
    await Fetch(
      "writeArticle",
      { markDown: marked.parse(value), user: user.id, image: cover, title: articleTitle },
      "post"
    );
    // console.log(marked.parse(newText))
  };
  useEffect(() => {}, [value]);
  return (
    <div className="writeArticle">
      
      <textarea
        type="text"
        placeholder="请输入文章标题..."
        className="articleTitle"
        value={articleTitle}
        onChange={(e) => setArticleTitle(e.target.value)}
      />
      <Editor
        value={value}
        preview={false}
        expand={true}
        subfield={true}
        toolbar={toolbar}
        lineNum={false}
        onChange={handleChange}
        height={"600px"}
        // onSave={saveMarkDown}
      />
      <div className="cover">
        <input
          type="text"
          value={cover}
          onChange={(e) => setCover(e.target.value)}
          placeholder='请输入封面图链接...'
        />
        <div className="image">
          {cover === ''?
            <AiOutlineFileAdd />:
            <img src={cover} alt="" />
          }
        </div>
      </div>
      <div className="btn" onClick={saveMarkDown}>
        <Button>保存</Button>
      </div>
    </div>
  );
};
export default WriteArticle;
