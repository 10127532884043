import "./App.css";
import React, { Component } from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import Header from './components/header'
import Footer from './components/footer'
import Index from './pages/home/home'
import Study from './pages/study/study'
import Say from './pages/say/say'
import Links from './pages/link/link'
import StyleDetails from './pages/studyDetails/styleDetails'
import './asstes/scss/app.scss'
import Background from "./pages/background/background.jsx"
import WithK from "./pages/whitK/withK.jsx";
import WriteArticle from "./pages/WriteArticle";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <div className="App">
        <HashRouter>
          <Header></Header>
          <Switch>
            <Route exact path="/" component={ Index } />
            <Route path="/study" component={ Study } />
            <Route path="/say" component={ Say } />
            <Route path="/links" component={ Links } />
            <Route path="/styleDetails/:id" component={ StyleDetails } />
            <Route path="/background" component={ Background } />
            <Route path="/withK" component= { WithK } />
            <WriteArticle path="/writeArticle" component={ WriteArticle } />
          </Switch>
          <Footer></Footer>
        </HashRouter>
      </div>
    );
  }
}

export default App;
