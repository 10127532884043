export const sayData = [
  {
    id: '1',
    name: "leic.yang",
    time: "2022-03-29",
    label: [
      "前端攻城狮",
      "宅男",
      "理想很丰满"
    ],
    message: "你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界"
  },
  {
    id: '2',
    name: "leic.yang",
    time: "2022-03-29",
    label: [
      "前端攻城狮",
      "宅男",
      "理想很丰满"
    ],
    message: "你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界"
  },
  {
    id: '3',
    name: "leic.yang",
    time: "2022-03-29",
    label: [
      "前端攻城狮",
      "宅男",
      "理想很丰满"
    ],
    message: "你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界"
  },
  {
    id: '4',
    name: "leic.yang",
    time: "2022-03-29",
    label: [
      "前端攻城狮",
      "宅男",
      "理想很丰满"
    ],
    message: "你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界你好啊世界"
  },
  
]
