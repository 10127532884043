import React, { Component } from 'react'

class link extends Component {
constructor(props){
super(props)
this.state = {}
}
 render(){
   return (
     <div className = 'link'>
       link
     </div>
   )
 }
}

export default link