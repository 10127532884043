import React, { useState, useEffect, useRef } from "react";
import { Image, Spin, Button, message } from "antd";
import "./background.scss";
import AutoResponsive from "autoresponsive-react";
import Fetch from "../../network/fetch";
import { AiOutlineLock } from 'react-icons/ai'
import Unauthorized from '../../components/Unauthorized'

const baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL_PROD;

const WithK = (props) => {
  const [pageState, setPageState] = useState([]);
  const [bodyWidth, setBodyWidth] = useState(1400);
  const [isloading, setIsloading] = useState(1);
  const [totleItems, setTotleItems] = useState(null);
  const [pageSize, setPageSize] = useState(30);
  const [limit, setLimit] = useState(1);
  const scrollElement = useRef(null);
  // get img
  useEffect(() => {
    const getData = async () => {
      try {
        const { result } = await Fetch("meAndMyLover", {
          pageIndex: limit,
          pageSize,
        });
        setPageState(result);
      } catch (error) {
        setPageState(false);
      }
    };
    getData();
  }, []);
  const removeImage = async (e, file, index) => {
    e.stopPropagation();
    e.preventDefault();
    const res = await Fetch(
      "removeImage",
      {
        file: file.src || file,
      },
      "post"
    );
    message.success(res.message);
    setPageState((newVlaue) => {
      newVlaue.splice(index, 1);
      return [...newVlaue];
    });
  };
  const loadMore = async () => {
    setIsloading(2);
    const { result } = await Fetch("meAndMyLover", {
      pageIndex: limit + 1,
      pageSize,
    });
    if (result.length < pageSize) {
      setIsloading(3);
    } else {
      setIsloading(1);
    }
    setPageState((value) => {
      value = [...value, ...result];
      return value;
    });
    setLimit(limit + 1);
  };
  const getAutoResponsiveProps = () => {
    return {
      itemMargin: 20,
      itemClassName: "productListItem",
      transitionDuration: ".5",
      containerWidth: document.body.clientWidth * 0.9,
    };
  };
  const setImageHeight = (e, index) => {
    setPageState((value) => {
      value[index].containerHeight =
        value[index].height /
        (value[index].width / ((document.body.clientWidth * 0.9) / 4 - 40));
      return [...value];
    });
  };
  return (
    <>
      {!pageState === false ? (
        <div className="background" ref={scrollElement}>
          {pageState?.length ? (
            <AutoResponsive {...getAutoResponsiveProps()}>
              {pageState.map((item, index) => {
                return (
                  <div
                    key={item._id}
                    className="productListItem backItem"
                    style={{
                      width: (window.document.body.clientWidth * 0.9) / 4 - 40,
                      height: item.containerHeight || "200px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <a href={`${baseUrl}${item.url}`} target="_blank">
                      <img
                        src={`${baseUrl}${item.url}`}
                        onLoad={(e) => setImageHeight(e, index)}
                        style={{ width: "100%" }}
                        alt=""
                      />
                      <button onClick={(e) => removeImage(e, item, index)}>
                        remove
                      </button>
                    </a>
                  </div>
                );
              })}
            </AutoResponsive>
          ) : (
            <div>暂无数据。</div>
          )}
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            {isloading === 1 ? (
              <Button className="loadMore" onClick={loadMore}>
                加载更多
              </Button>
            ) : isloading === 2 ? (
              <Spin tip="Loading..."></Spin>
            ) : (
              <span className="noMore">没有更多了。</span>
            )}
          </div>
        </div>
      ) : (
        <Unauthorized />
      )}
    </>
  );
};
export default WithK;
