import React, { useState, useEffect } from "react";
import { Image } from "antd";
import "./styleDetails.scss";
import Fetch from "../../network/fetch";

const StyleDetails = (props) => {
  const [pageState, setPageState] = useState({})
  useEffect( async ()=>{
    const res = await Fetch(`article`, { id: props.match.params.id })
    setPageState(res.result)
  }, [])
  return (
    <div className="styleDetails">
        <div className="details">
          <img src={pageState.image} className="articleBanner" alt="" />
        </div>
      </div>
  )
}

export default StyleDetails;
