import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Typography } from "antd";
import { CloseCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import "../asstes/scss/loginModal.scss";

const { Text, Title } = Typography;
const LoginModal = (props) => {
  const {
    open,
    onClose,
    chnageUserName = () => {},
    chnagePassword = () => {},
    changeVerifiCode = () => {},
    getVerifiCode = () => {},
    login = () => {},
    register = () => {},
    verifiDisabled
  } = props;
  const [loading, setLoading] = useState(false);
  const [registerInfo, setRegisterInfo] = useState(true);
  const getVerifi = useRef(null)
  const prevent = (event) => {};
  const onKeyDownPWD = (e) => {
    if (e.keyCode === 13) {
      login();
    }
  };
  return (
    <div
      className={`login-modal-container ${
        open ? "login-modal-container-in" : ""
      }`}
    >
      <div className="login-modal" onClick={prevent} >
        <div className="login-modal-close">
        <ArrowLeftOutlined onClick={e=>setRegisterInfo(true)} /><CloseCircleOutlined onClick={onClose} />
        </div>
        {registerInfo ? (
          <>
            <Title className="login-modal-title" level={3}>
              登录 jsyang
            </Title>
            <div className="login-modal-input-container">
              <span className="input-title">邮件*</span>
              <Input
                placeholder=""
                className="userName login-modal-input"
                onInput={(e) => {
                  chnageUserName(e);
                }}
              ></Input>
            </div>
            <div className="login-modal-input-container">
              <span className="input-title">密码*</span>
              <Input.Password
                placeholder=""
                className="login-modal-input"
                onInput={(e) => chnagePassword(e)}
                onKeyDown={onKeyDownPWD}
              ></Input.Password>
            </div>
            <div className="loginButton">
              <Button
                className="login"
                shape="round"
                loading={loading}
                onClick={login}
              >
                登录
              </Button>
              <Button
                className="register"
                onClick={(e) => setRegisterInfo(false)}
                shape="round"
              >
                注册
              </Button>
            </div>
          </>
        ) : (
          <div className="register-container">
            <Title className="login-modal-title" level={3}>
              注册 jsyang
            </Title>
            <div className="login-modal-input-container">
              <span className="input-title">邮件*</span>
              <Input
                placeholder=""
                className="userName login-modal-input"
                onInput={(e) => {
                  chnageUserName(e);
                }}
              ></Input>
            </div>
            <div className="login-modal-input-container">
              <span className="input-title">密码*</span>
              <Input.Password
                placeholder=""
                className="login-modal-input"
                onInput={(e) => chnagePassword(e)}
                onKeyDown={onKeyDownPWD}
              ></Input.Password>
            </div>
            <div className="verifiCode login-modal-input-container">
              <span className="input-title">邮件*</span>
              <div>
                <Input
                  placeholder=""
                  className=" login-modal-input"
                  onInput={(e) => {
                    changeVerifiCode(e);
                  }}
                ></Input>
                <Button disabled={verifiDisabled} ref={getVerifi} onClick={e=>getVerifiCode(getVerifi)}>获取验证码</Button>
              </div>
            </div>
            <Button
                className="register"
                onClick={register}
                shape="round"
              >
                注册
              </Button>
          </div>
        )}
      </div>
    </div>
  );
};
export default LoginModal;
