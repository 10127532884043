import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import Fetch from '../../network/fetch'
const WebsitInfo = (props) => {
  const [pageState, setPageState] = useState(null);
  useEffect(async() => {
    const res = await Fetch('api/websiteInfo')
    setPageState(res);
    // Axios({
    //   method: "get",
    //   url: "/api/websiteInfo",
    // }).then((res) => {
    //   console.log(res.data);
    //   setPageState(res.data);
    // });
  }, []);
  if (pageState === null) {
    return <div>loading...</div>;
  }
  return (
    <div className="websiteInfo">
      <div className="personalInfo">
        <div className="logo">
          <img src="http://jsyang.top:2001/logo.jpeg" alt="" />
        </div>
        <p className="name">leic.yang</p>
        <p className="totle">
          <span>
            文章
            <br />
            <span>{pageState.articles}</span>
          </span>
          <span>
            标签
            <br />
            <span>{pageState.label}</span>
          </span>
        </p>
        <div className="contactInfo">
            <a href="https://github.com/yang-leicheng" target="">
              <img src={require('../../asstes/images/GitHub.svg').default} alt="" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100078271675170" target="">
              <img src={require('../../asstes/images/facebook.svg').default} alt="" />
            </a>
            <a href="https://gitee.com/yang-leicheng" target="">
              <img src={require('../../asstes/images/gitee.svg').default} alt="" />
            </a>
            <a href="https://twitter.com/yang77048194" target="">
              <img src={require('../../asstes/images/Twitter.svg').default} alt="" />
            </a>
        </div>
      </div>
      <div className="allLabel">
        {pageState.allLabel.map((label, index) => (
          <Link to="">{label.label}</Link>
        ))}
      </div>
      <div className="casual">
        <p className="p1">留言</p>
        <ul>
          {pageState.casual.map((item, index) => (
            <li key={index}>
              <div className="info">
                <div className="image">
                  <img src={'http://jsyang.top/logo.jpeg'} alt="" />
                </div>
                <div className="">
                  <p className="p1">
                    <span>yang</span>
                    <i>2021.12.14</i>
                  </p>
                  <p className="p2">
                    {
                      item.label.map(label=>(
                        <span>{label}</span>
                      ))
                    }
                  </p>
                </div>
              </div>
              <div className="p3">
                {item.content}
              </div>
              <div className="operation">
                <span>喜欢</span>
                <span>评论</span>
                <span>
                  <a href="#/">查看详情</a>
                </span>
              </div>
            </li>
          ))}
          <Link className="loadMore" to="">
            查看更多
          </Link>
        </ul>
      </div>
    </div>
  );
};
export default WebsitInfo;
