import React, { useState, useEffect, useRef } from "react";
import { Image, Spin, Button, message, Popconfirm } from "antd";
import "./background.scss";
import AutoResponsive from "autoresponsive-react";
import Axios from "axios";
import Fetch from "../../network/fetch";
const baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL_PROD;

const Background = (props) => {
  const [pageState, setPageState] = useState([]);
  const [bodyWidth, setBodyWidth] = useState(1400);
  const [isloading, setIsloading] = useState(1);
  const [totleItems, setTotleItems] = useState(null);
  const [limit, setLimit] = useState(1);
  const [pageSize, setPageSize] = useState(30)
  const scrollElement = useRef(null);
  const [isAdmin, setIsAdmin] = useState(false);
  // get img
  useEffect(async () => {
    const res = await Fetch("aaa", { pageIndex: limit, pageSize } );
    setPageState(res.result);
    const { result } = await Fetch("userInfo");
    setIsAdmin(result.isAdmin);
  }, []);
  const removeImage = async (e, file, index) => {
    e.stopPropagation();
    e.preventDefault();
    const res = await Fetch(
      "removeImage",
      {
        file: file.src || file,
      },
      "post"
    );
    message.success(res.message);
    setPageState((newVlaue) => {
      newVlaue.splice(index, 1);
      return [...newVlaue];
    });
  };
  const loadMore = async () => {
    setIsloading(2);
    const { result } = await Fetch("aaa", { pageIndex: limit+1, pageSize });
    if(result.length<pageSize){
      setIsloading(3);
    }else{
      setIsloading(1);
    }
    setPageState(value=>{
      value = [...value, ...result]
      return value
    })
    setLimit(limit + 1);
  };
  const getAutoResponsiveProps = () => {
    return {
      itemMargin: 20,
      itemClassName: "productListItem",
      transitionDuration: ".5",
      containerWidth: document.body.clientWidth * 0.9,
    };
  };
  const setImageHeight = (e, index) => {
    setPageState((value) => {
      value[index].containerHeight =
        value[index].height /
        (value[index].width / ((document.body.clientWidth * 0.9) / 4 - 40));
      return [...value];
    });
  };
  return (
    <div className="background" ref={scrollElement}>
      {pageState && pageState.length && (
        <AutoResponsive {...getAutoResponsiveProps()}>
          {pageState.map((item, index) => {
            return (
              <div
                key={item._id}
                className="productListItem backItem"
                style={{
                  width: (document.body.clientWidth * 0.9) / 4 - 40,
                  height: item.containerHeight || "200px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <a href={`${baseUrl}${item.url}`} target="_blank">
                  <img
                    src={`${baseUrl}${item.url}`}
                    onLoad={(e) => setImageHeight(e, index)}
                    style={{ width: "100%" }}
                    alt=""
                  />
                  {isAdmin ? (
                    <Popconfirm
                      title="Are you sure to delete this img?"
                      onConfirm={(e) => removeImage(e, item, index)}
                    >
                      <button>remove</button>
                    </Popconfirm>
                  ) : (
                    ""
                  )}
                </a>
                {/* <Image
                src={`http://jsyang.top:2001/${item.src || item}`}
                onLoad={(e) => setImageHeight(e, index)}
              /> */}
              </div>
            );
          })}
        </AutoResponsive>
      )}
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        {isloading === 1 ? (
          <Button className="loadMore" onClick={loadMore}>
            加载更多
          </Button>
        ) : isloading === 2 ? (
          <Spin tip="Loading..."></Spin>
        ) : (
          <span className="noMore">没有更多了。</span>
        )}
      </div>
    </div>
  );
};
export default Background;
