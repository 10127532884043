import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { Spin } from "antd";
import Axios from "axios";
import { getStudyList } from "../../network/home";
import Fetch from "../../network/fetch";
class contentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      load: 1,
      limit: 1,
      count: 6,
    };
  }
  loadMore = (e) => {
    this.setState({
      limit: this.state.limit + 1,
      load: 2,
    });
    setTimeout(() => {
      this.getStudyList();
    }, 500);
  };
  getStudyList = async () => {
    const { result } = await Fetch("api/styleList", {
      pageIndex: this.state.limit,
      pageSize: this.state.count,
    }, 'post')
    this.setState({
      list: this.state.list.concat(result),
      load: result.length < this.state.count ? 3 : 1,
    });
    // Axios({
    //   method: "post",
    //   url: "/api/styleList",
    //   data: {
    //     limit: this.state.limit,
    //     count: this.state.count,
    //   },
    // }).then((res) => {
    //   this.setState({
    //     list: this.state.list.concat(res.data),
    //     load: res.data.length < 6 ? 6 : 1,
    //   });
    // });
  };
  componentDidMount() {
    this.getStudyList();
    // getStudyList({
    //   limit: this.state.limit,
    //   count: this.state.count
    // })
  }
  render() {
    return (
      <div className="studyList">
        <ul>
          {this.state.list.map((item, index) => {
            if (index % 2 === 0) {
              return (
                <li key={index}>
                  <Link to={`/styleDetails/${item._id}`}>
                    <div className="image">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="words">
                      <p className="p1">{item.title}</p>
                      <p className="time"> {item.time} </p>
                      <p className="p2">{item.content}</p>
                    </div>
                  </Link>
                </li>
              );
            } else {
              return (
                <li key={index}>
                  <Link to={`/styleDetails/${item._id}`}>
                    <div className="words">
                      <p className="p1">{item.title}</p>
                      <p className="time">{item.time}</p>
                      <p className="p2">{item.content}</p>
                    </div>
                    <div className="image">
                      <img src={item.image} alt="" />
                    </div>
                  </Link>
                </li>
              );
            }
          })}
        </ul>
        <div className="load">
          {this.state.load === 1 ? (
            <Button className="loadMore" onClick={this.loadMore}>
              加载更多
            </Button>
          ) : this.state.load === 2 ? (
            <Spin tip="Loading..."></Spin>
          ) : (
            <span className="noMore">没有更多了。</span>
          )}
        </div>
      </div>
    );
  }
}

export default contentList;
