import React, { Component } from 'react'

class upMaster extends Component {
constructor(props){
super(props)
this.state = {}
}
 render(){
   return (
     <div className = 'upMaster'>
       
     </div>
   )
 }
}

export default upMaster