import React, { Component } from 'react'

class study extends Component {
constructor(props){
super(props)
this.state = {}
}
 render(){
   return (
     <div className = 'study'>
       study
     </div>
   )
 }
}

export default study
