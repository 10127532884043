import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, message, Input, Drawer, Avatar, Popover, List } from "antd";
import "../asstes/scss/header.scss";
import cheng from "./../asstes/images/cheng.png";
import axios from "axios";
import Fetch from "../network/fetch";
import { FormOutlined, UserOutlined } from "@ant-design/icons";
import LoginModal from "./LoginModal";
import { AiOutlineForm } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";
import { BiUser, BiMailSend, BiLogOut } from "react-icons/bi";
class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerOut: false,
      navList: [
        {
          content: "index",
          path: "/",
        },
        {
          content: "with K",
          path: "/withK",
        },
        {
          content: "study",
          path: "/study",
        },
        {
          content: "say",
          path: "/say",
        },
        {
          content: "links",
          path: "/links",
        },
      ],
      navActive: 0,
      visible: false,
      userName: "",
      passWord: "",
      loggin: false,
      open: false,
      verifiCode: "",
      countDown: 0,
      verifiDisabled: false,
    };
    message.config({
      maxCount: 1,
    });
  }
  loginShow = () => {
    this.setState({
      visible: true,
    });
  };
  login = async () => {
    try {
      const res = await Fetch(
        "login",
        {
          name: this.state.userName,
          password: this.state.passWord,
        },
        "post"
      );
      console.log("zhelizhixingle");
      message.success({
        content: res.message,
      });
      this.setState({
        visible: false,
      });
      localStorage.setItem("jsyang-token", res.token);
      this.getLoginActive();
    } catch (error) {
      message.error({
        content: error.message,
      });
      this.setState({
        visible: false,
      });
    }
  };
  loginOut = () => {
    localStorage.removeItem("jsyang-token");
    this.getLoginActive();
  };
  getLoginActive = () => {
    this.setState({
      loggin: Boolean(localStorage.getItem("jsyang-token")),
    });
  };
  changeActive = (index) => {
    this.setState({
      navActive: index,
    });
  };
  chnageUserName = (e) => {
    this.setState({
      userName: e.target.value,
    });
  };
  changeVerifiCode = (e) => {
    this.setState({
      verifiCode: e.target.value,
    });
  };

  closeLoginModal = () => {
    this.setState({
      visible: false,
    });
  };
  chnagePassword = (e) => {
    this.setState({
      passWord: e.target.value,
    });
  };
  componentDidMount() {
    window.addEventListener("scroll", this.winScorll, true);
    this.getLoginActive();
    this.setState({
      navActive: this.state.navList.findIndex((item, index) => {
        return item.path === window.location.hash.slice(1);
      }),
    });
  }
  winScorll = (e) => {
    if (e.srcElement.scrollTop > 150) {
      this.setState({
        headerOut: true,
      });
    } else {
      this.setState({
        headerOut: false,
      });
    }
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  getVerifiCode = async (ref = "") => {
    let timer;
    try {
      const res = await Fetch(
        "sendVerification",
        {
          name: this.state.userName,
          password: this.state.passWord,
          verifiCode: this.state.verifiCode,
        },
        "post"
      );
      this.setState({
        verifiDisabled: true,
      });
      timer = setInterval(() => {
        this.setState((state, props) => {
          ref.current.innerHTML = `${60 - (state.countDown + 1)}秒后重新发送`;
          if (state.countDown + 1 >= 60) {
            clearInterval(timer);
            ref.current.innerHTML = `获取验证码`;
            this.setState({
              verifiDisabled: false,
            });
          }
          return {
            countDown: state.countDown + 1,
          };
        });
      }, 1000);
      message[res.status]({
        content: res.message,
      });
    } catch (error) {
      message.error({
        content: error.message,
      });
    }
  };
  register = async () => {
    try {
      const res = await Fetch(
        "register",
        {
          name: this.state.userName,
          password: this.state.passWord,
          verifiCode: this.state.verifiCode,
        },
        "post"
      );
      this.setState({
        visible: false,
      });
      message[res.status]({
        content: res.message,
      });
    } catch (error) {
      message.error({
        content: error.message,
      });
      this.setState({
        visible: false,
      });
    }
  };

  render() {
    const notiveContent = (
      <ul>
        <li>
          <BiUser size={18} />
          个人信息
        </li>
        <li>
          <BiMailSend size={18} />
          留言
        </li>
        <li onClick={this.loginOut}>
          <BiLogOut size={18} />
          登出
        </li>
      </ul>
    );
    const { navList, navActive, visible, headerOut } = this.state;
    return (
      <>
        <div className={"header w " + (headerOut ? "headerOut" : "")}>
          <h1 className="logo">
            <Link to="/">
              YANG <img src={cheng} alt="" />
            </Link>
          </h1>
          <ul className="nav">
            {navList.map((item, index) => (
              <li
                className={navActive === index ? "on" : ""}
                key={index}
                onClick={() => {
                  this.changeActive(index);
                }}
              >
                <Link
                  to={{
                    pathname: item.path,
                  }}
                >
                  {item.content}
                </Link>
              </li>
            ))}
          </ul>
          {!this.state.loggin ? (
            <Button className="login" shape="round" onClick={this.loginShow}>
              LOGIN
            </Button>
          ) : (
            <div className="userIcons">
              <Popover
                className="avatar"
                content={notiveContent}
                trigger="click"
              >
                <Avatar size={30} icon={<UserOutlined />} />
              </Popover>
              <Avatar
                className="avatar noticeIcon"
                size={30}
                icon={<IoMdNotificationsOutline size={20} />}
              />
              <Avatar
                className="avatar"
                size={30}
                icon={
                  <Link to="writeArticle" className="editButton">
                    <AiOutlineForm size={20} />
                  </Link>
                }
              />
            </div>
          )}
        </div>
        <LoginModal
          onClick={this.loginShow}
          onClose={this.closeLoginModal}
          chnageUserName={this.chnageUserName}
          chnagePassword={this.chnagePassword}
          login={this.login}
          register={this.register}
          open={this.state.visible}
          changeVerifiCode={this.changeVerifiCode}
          getVerifiCode={this.getVerifiCode}
          verifiDisabled={this.state.verifiDisabled}
        />
      </>
    );
  }
}

export default header;
