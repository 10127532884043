import React, { useState } from "react";
import Like from "../../components/Like";
import { Button } from 'antd'
import { EditOutlined, ShareAltOutlined, MoreOutlined } from "@ant-design/icons";
const SayItem = (props) => {
  const { data } = props;
  return (
    <li>
      <div className="sayTo">
        <div className="left">
          <div className="image">
            <img src="http://jsyang.top/images/WechatIMG32.jpeg" alt="" />
          </div>
          <div className="nameTimeLabel">
            <span className="name">{data.name}</span>
            <span className="time">{data.time}</span>
            <div className="label">
              {data.label.map((label) => {
                return <span key={label}>{label}</span>;
              })}
            </div>
          </div>
        </div>
        <div className="right">
        <Button type="text" shape="circle"><MoreOutlined style={{fontSize: '22px'}} /></Button>
        </div>
      </div>
      <p className="message">{data.message}</p>
      <div className="operation">
        <span className="">
          喜欢 <Like />
        </span>
        <span>
          评论 <EditOutlined />
        </span>
        <span>
          转发 <ShareAltOutlined />
        </span>
      </div>
    </li>
  );
};
export default SayItem;
